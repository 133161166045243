<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>商品原価更新依頼</v-card-title>
          <v-card-text>
            <v-form ref="form" onsubmit="return false;">
              <v-row>
                <v-col cols="12" sm="3">
                  <v-radio-group
                    v-model="processStatus"
                    label="処理ステータス"
                    mandatory
                    dense
                    hide-details="false"
                  >
                    <v-radio label="承認待ち" value="0"></v-radio>
                    <v-radio label="承認済" value="1"></v-radio>
                    <v-radio label="却下" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <!-- 品番検索 -->
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="modelNumberSearch"
                    label="品番検索"
                    :rules="[limit_length50]"
                    :hint="`部分一致`"
                    persistent-hint
                    counter="50"
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions class="justify-center">
              <!-- 検索ボタン -->
              <v-btn color="secondary" v-on:click="onClickSearch" rounded>
                <v-icon left> mdi-magnify </v-icon>検索
              </v-btn>
              <!-- 一括計上 -->
              <v-btn
                color="secondary"
                v-on:click="onClickAllAppropriation"
                rounded
                class="my-2"
                ><v-icon left> mdi-database-arrow-up </v-icon>一括計上
              </v-btn>
              <!-- <span v-if="success">バリデーションOK！</span> -->
            </v-card-actions>
          </v-card-text>
          <v-card-text>
            <v-form ref="formDetails">
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                class="elevation-5 mt-5 scroll-lock-search"
                v-model="selected"
                show-select
                item-key="requestNo"
                multi-sort
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>依頼一覧</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-toolbar-title> {{ items.length }}件 </v-toolbar-title>
                  </v-toolbar>
                </template>
                <!-- 定価 -->
                <template v-slot:[`item.listPrice`]="{ item }">
                  {{ item.listPrice == null || item.listPrice == "" ? item.listPrice : new Number(item.listPrice).toLocaleString() }}
                </template>
                <!-- 卸価格 -->
                <template v-slot:[`item.wholesale`]="{ item }">
                  {{ item.wholesale == null || item.wholesale == "" ? item.wholesale : new Number(item.wholesale).toLocaleString() }}
                </template>
                <!-- NET -->
                <template v-slot:[`item.net`]="{ item }">
                  {{ item.net == null || item.net == "" ? item.net : new Number(item.net).toLocaleString() }}
                </template>
                <!-- 最終仕入価格 -->
                <template v-slot:[`item.lastCost`]="{ item }">
                  {{ item.lastCost == null || item.lastCost == "" ? item.lastCost : new Number(item.lastCost).toLocaleString() }}
                </template>
                <!-- 承認 -->
                <template v-slot:[`item.approval`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        x-small
                        fab
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!(item.processingCheck == 0)"
                        @click="onClickApproval(item)"
                        >承認
                      </v-btn>
                    </template>
                    <span>選択した内容でDBに登録する。</span>
                  </v-tooltip>
                </template>
                <!-- 却下 -->
                <template v-slot:[`item.rejected`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        x-small
                        fab
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!(item.processingCheck == 0)"
                        @click="onClickRejected(item)"
                        >却下
                      </v-btn>
                    </template>
                    <span>テキスト編集用モーダルを開いてメール送信する。</span>
                      <v-dialog :retain-focus="false" v-model="item.dialog" max-width="800px">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">依頼却下理由入力</span>
                          </v-card-title>
                          <v-card-text>
                            <div style="padding-bottom:12px;">送信先メールアドレス：{{item.requesterEmail}}</div>
                            <v-textarea
                              v-model="item.rejectionMsg"
                              label="却下理由"
                              counter="255"
                              rows="1"
                              :rules="[limit_length255]"
                              clearable
                              auto-grow
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color="secondary" rounded @click="onClickClose(item)">
                              キャンセル
                            </v-btn>
                            <v-btn dark color="secondary" rounded @click="onClickSendEmail(item)">
                              OK
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                  </v-tooltip>
                </template>  
                <!-- 処理済み -->
                <template v-slot:[`item.processingCheck`]="{ item }">
                  <v-simple-checkbox
                    v-model="item.processingCheck"
                    color="secondary"
                    :ripple="false"
                    disabled
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AWS from 'aws-sdk';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify'
import {
  listT_CENTER_SHOHIN_REQUEST,
  getM_SHOKISETTEI,
} from "@/graphql/queries";
import {
  executeTransactSql,
  executeHanbaikanriSql,
} from "@/graphql/mutations";
import { AWS_DEFAULT_REGION, SES_API_VERSION } from '@/assets/js/const';
import { CONFIRM_MESSAGE_UPDATE_REQUEST_REJECT, ALERT_MESSAGE_COMMON_ERROR, ALERT_MESSAGE_EMAIL_AUTH_ERROR } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';

export default {
  name: "ProductPriceUpdateRequest",

  // 件数
  Number_of_records: "901",

  data: () => ({
    headers: [
      { text: "依頼日", value: "requestDate", width: "100px" },
      { text: "依頼No", value: "requestNo", width: "70px" },
      { text: "商品コード", value: "productCode", width: "100px" },
      { text: "品番", value: "productNo", width: "100px" },
      { text: "商品名", value: "productName", width: "200px" },
      { text: "定価", value: "listPrice", width: "70px" },
      { text: "卸価格", value: "wholesale", width: "70px" },
      { text: "NET", value: "net", width: "70px" },
      { text: "最終仕入価格", value: "lastCost", width: "60px" },
      { text: "仕入先名", value: "supplierName", width: "200px" },
      { text: "", value: "approval", width: "50px", sortable: false },
      { text: "", value: "rejected", width: "50px", sortable: false },
      { text: "処理済み", value: "processingCheck", width: "40px" },
    ],
    // 処理ステータス
    processStatus: "",
    // 品番検索
    modelNumberSearch: "",
    // Datatablesのデータ
    items: [],
    // チェックボックス
    selected: [],
    // 承認・一括計上アイテム
    selectItem: [],
    //他シスに登録されているデータ
    resultClassificationData: "",
    // 入力規則
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      value == null || value.length <= 50 || "50文字以内で入力してください",
    // 文字数の制約 最大255文字
    limit_length255: (value) =>
      value == null || value.length <= 255 || "255文字以内で入力してください",
  }),
  /**
   * created ライフサイクルフック
   */
  created: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // 初期化処理の実行
    this.initialize();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.resetValidate();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検索条件
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // ログイン中のユーザー情報を取得
      if (!(await this.getLoginUserInfo())) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      const user = this.$store.getters.user;
      // ログインユーザーネーム
      this.loginUserName = user.TANTOSHA_NAME;
      this.loginUserAddress = user.TANTOSHA_ADDRESS;
      // データ取得
      let condition = { BUMON_CD: user.BUMON_CD };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.getM_SHOKISETTEI;
          this.departmentInfo = resultData;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 検索ボタン押下処理
     * ・バリデーションチェック
     */
    async onClickSearch() {
      // メソッド名を定義
      const method_name="onClickSearch"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.selected = [];
      // バリデーションチェックでエラーの際は検索しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 検索処理
      if (!(await this.searchResultList())) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 検索処理
     * ・入力データでSQLを作成する
     * ・取得データを「依頼一覧」に表示する
     */
    async searchResultList() {
      // メソッド名を定義
      const method_name="searchResultList"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 検索条件
      let whereOptions = "";
      // 処理ステータスのみで検索
        whereOptions += " AND SHORI_STATUS_KBN = " + this.processStatus;
      // 処理ステータス、品番で検索
      if (this.modelNumberSearch) {
        whereOptions += " AND HINBAN_NO LIKE '%" + this.modelNumberSearch +"%'";
      }
      whereOptions += " ORDER BY IRAI_NO ASC";
      let condition = { where_options: whereOptions };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listT_CENTER_SHOHIN_REQUEST, condition)
        );

        // 結果判定
        if (result.data.listT_CENTER_SHOHIN_REQUEST) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listT_CENTER_SHOHIN_REQUEST;
          this.items = [];
          for (const data of resultData) {
            this.items.push({
              requestDate: data.IRAI_DATE,
              requestNo: data.IRAI_NO,
              productCode: data.SHOHIN_CD,
              productNo: data.HINBAN_NO,
              productName: data.SHOHIN_NAME,
              listPrice: data.TEKA_GK,
              wholesale: data.OKYAKUSAMA_OROSHIKAKAKU_GK,
              net: data.NET_GK,
              lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
              supplierName: data.SHIIRESAKI_NAME,
              processingCheck: Boolean(data.SHORI_STATUS_KBN),
              requesterEmail: data.SHONIN_IRAISHA_EMAIL,
              dialog: false,
              rejectionMsg: null,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listT_CENTER_SHOHIN_REQUEST", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          console.log("データ0件ログ");
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listT_CENTER_SHOHIN_REQUEST", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log("異常系ログ:" + (error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listT_CENTER_SHOHIN_REQUEST", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 一括計上ボタンボタン押下処理
     * ・商品の原価変更を行う
     * ・申請されたデータで登録する
     */
    async onClickAllAppropriation() {
      // メソッド名を定義
      const method_name="onClickAllAppropriation"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      //エラーメッセージ
      if(this.selected == ""){
        alert("商品が選択されていません。");
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 商品の更新処理
      if (!(await this.updateProduct(this.selected))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 承認ボタン押下処理
     * ・商品の原価変更を行う
     * ・申請されたデータで登録する
     */
    async onClickApproval(item) {
      // メソッド名を定義
      const method_name="onClickApproval"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      this.selectItem = [];
      this.selectItem.push(item);
      
      // 商品の更新処理
      if (!(await this.updateProduct(this.selectItem))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 選択レコードがあれば解除
      this.selected = [];
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 承認、一括計上された商品の更新
     * ・申請されたデータでSQLを作成し、データを登録する
     * ・処理済みフラグを立てる
     */
    async updateProduct(selectItem) {
      // メソッド名を定義
      const method_name="updateProduct"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 処理済みエラーメッセージ
      for (const data of selectItem){
        if (data.processingCheck !== false) {
          alert("既に処理済みです。");
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-start)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        }
      }
      // レコード更新前のデータ取得
      // let result = false;
      let sqlSearchList = [];
      for (const data of selectItem){
        sqlSearchList.push("select SH_SIRTNA,SH_ZAITNA,SH_FRITNA from SHOHIN WHERE SH_SHOCD = " + data.productCode);
      }
      if (!(await this.executeHanbaikanriSql(sqlSearchList))) {
        return false;
      }
      for (let i = 0; i < selectItem.length; i++) {
        // エラーメッセージ
        let alertMsg = "\n\n依頼日　 ：" + selectItem[i].requestDate + "\n依頼No　：" + selectItem[i].requestNo + "\n商品コード ：" + selectItem[i].productCode;

        // エラーチェック：商品コードが1万番台以下
        if(selectItem[i].productCode < 100000){
          alert("商品コードがサン電子商品です。" + alertMsg);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-start)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        }

        // エラーチェック：最終仕入価格が0
        if(selectItem[i].lastCost == null || selectItem[i].lastCost == 0){
          alert("最終仕入価格が0です。" + alertMsg);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-start)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        }

        // エラーチェック：商品の存在チェック
        if (this.resultClassificationData[i][0] == null) {
          alert("登録されていない商品のため変更できません。" + alertMsg);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-start)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        }

        let productDataMsg = "現在の商品データは以下です。\n仕入単価："+ this.resultClassificationData[i][0].SH_SIRTNA + 
        "\n在庫単価：" + this.resultClassificationData[i][0].SH_ZAITNA + "\n原価　　：" + this.resultClassificationData[i][0].SH_FRITNA + 
        "\n\n最終仕入価格「" + selectItem[i].lastCost
        // 既に変更されている場合
        if (this.resultClassificationData[i][0].SH_SIRTNA == selectItem[i].lastCost
            || this.resultClassificationData[i][0].SH_ZAITNA == selectItem[i].lastCost
            || this.resultClassificationData[i][0].SH_FRITNA == selectItem[i].lastCost) {
          alert(productDataMsg + "」と変更がありません。");
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-start)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        }
        // レコード更新前の確認ダイアログ
        let resultConfirm = confirm(productDataMsg + "」に書き換えます。よろしいですか？");
        if( !resultConfirm ) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-start)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        }
      }

      // DBに入力データ登録 
      let sqlUpdList = [];
      let date = new Date();
      let mm = ("0"+(date.getMonth()+1)).slice(-2); // 08
      let dd = ("0"+date.getDate()).slice(-2); // 08
      let nowDate = String(date.getFullYear()) + mm + dd;
      // 申請されたデータでSQLを作成し、データを登録する
      for (const data of selectItem){
        sqlUpdList.push (
          " UPDATE SHOHIN SET" +
            " SH_SIRTNA = " + data.lastCost + "," +
            " SH_ZAITNA = " + data.lastCost + "," +
            " SH_FRITNA = " + data.lastCost + "," +
            " SH_SYUDT = " + nowDate +
            " WHERE SH_SHOCD = " + data.productCode
        );
      }
      if (!(await this.executeHanbaikanriSql(sqlUpdList))) {
        return false;
      }

      // 処理ステータスの更新
      if (!(await this.updateProcessStatus(selectItem, 1))) {
        return false;
      }

      this.selected = [];

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * SHOHINデータベースの照会
     * ・SHOHINデータベースからのデータ取得
     */
    async executeHanbaikanriSql(sqlSearchList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検索条件
      this.resultClassificationData = null;
      let condition = { SQLs: sqlSearchList };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            const message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error("errorMessage = " + JSON.stringify(errorMessage));
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            console.log(
              "responseBody.data" + JSON.stringify(responseBody.data)
            );
            this.resultClassificationData = responseBody.data;
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return true;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.error("error" + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 処理ステータスの更新
     * ・ステータスをDBに反映
     */
    async updateProcessStatus(selectItem, processStatus) {
      // メソッド名を定義
      const method_name="updateProcessStatus"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      //処理ステータスの更新
      let editedIndex = [];
      for(const data of selectItem){
        editedIndex.push (
          this.items.indexOf(data)
        );
      }
      let sqlList = [];
      for (const data of selectItem){
        sqlList.push (
          "UPDATE T_CENTER_SHOHIN_REQUEST SET" +
            " SHORI_STATUS_KBN = " + processStatus +
            " WHERE IRAI_NO = " + data.requestNo
        );
      }

      let condition = {
        SQLs: sqlList,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeTransactSql.body
          );
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            const message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error(" errorMessage = " + JSON.stringify(errorMessage));
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            for (let i = editedIndex.length - 1; i >= 0; i--){
              this.items.splice(editedIndex[i], 1);
            }
            console.log(
              "responseBody.data" + JSON.stringify(responseBody.data)
            );
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return true;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.error("error" + JSON.stringify(error));
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 却下ボタン押下処理
     * ・ダイアログを表示する
     */
    async onClickRejected(item) {
      // メソッド名を定義
      const method_name="onClickRejected"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // Eメールが未承認の場合はエラー
      if (!(await this.checkAuthUser())) {
        alert(ALERT_MESSAGE_EMAIL_AUTH_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // バリデーションチェックでエラーの際は登録しない
      let result = confirm(CONFIRM_MESSAGE_UPDATE_REQUEST_REJECT);
      if( !result ) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      item.dialog = true;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 却下理由の送信処理
     * ・ユーザー情報の取得
     * ・対象の依頼データの追加
     */
    async onClickSendEmail(item) {
      // メソッド名を定義
      const method_name="onClickSendEmail"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(Amplify-start)
      addLog('INFO', {message: MSG.INFO["1008"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // バリデーションチェックでエラーの際は送信・更新しない
      if (!(await this.validateCheckDetails())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      try {
        const credentials = await Auth.currentCredentials();
        const ses = new AWS.SES({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          region: AWS_DEFAULT_REGION,
        });
        // ログイン中のユーザー情報を取得
        const personInCharge = this.loginUserName;
        const fromAddress = this.loginUserAddress;
        const toAddress = item.requesterEmail;
        const departmentName = this.departmentInfo.BUMON_NAME;
        const departmentPhone = this.departmentInfo.DENWA_NO;
        const departmentFax = this.departmentInfo.FAX_NO;
        let rejectionMsg;
        rejectionMsg = !item.rejectionMsg ? "" : `${item.rejectionMsg}`;
        let message = `以下の依頼が却下されました。\n\n` +
        `依頼日       ：${item.requestDate}\n` +
        `依頼No      ：${item.requestNo}\n` +
        `商品コード：${item.productCode}\n` +
        `却下理由    ：\n${rejectionMsg}\n\n\n` +
        `サン電子株式会社  ${departmentName}\n` +
        `${personInCharge}\n` +
        `TEL：${departmentPhone}  FAX：${departmentFax}\n` +
        `E-Mail：${fromAddress}`;
        let params = {
            Destination: {
              ToAddresses: [toAddress]
            },
            Message: {
              Body: {
              Text: { Data: message },
              },
              Subject: { Data: `原価更新依頼が却下されました` },
            },
            Source: fromAddress,
        };

        const result = await ses.sendEmail(params).promise();
        console.log(result);
      } catch (error) {
        console.log(error);
        // ログ出力(Amplifyの実行でexceptionが発生)
        addLog('ERROR', {message: MSG.ERROR["3012"],}, this.$route.name, method_name, TYPE["0004"], error);
      }
      // 処理ステータスの更新
      this.selectItem = [];
      this.selectItem.push(item);
      if (!(await this.updateProcessStatus(this.selectItem, 2))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 選択レコードがあれば解除
      this.selected = [];
      // ダイアログを閉じる
      await this.close(item);

      // ローディングを解除
      this.$store.commit("setLoading", false);

      // メッセージを出力する
      alert("依頼を却下しました。");

      // ログ出力(Amplify-end)
      addLog('INFO', {message: MSG.INFO["1009"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 却下ダイアログのクローズ処理
     */
    onClickClose(item) {
      // メソッド名を定義
      const method_name="onClickClose"
      // オペレーションタイプを定義
      const operation_type="0002"
      // 却下理由テキストを削除
      item.rejectionMsg = "";
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.close(item);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * バリデーションチェックをリセット
     */
    resetValidate() {
      // メソッド名を定義
      const method_name="resetValidate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ヘッダーのバリデーションをリセット
      this.$refs.form.resetValidation();
      // モーダルのバリデーションをリセット
      if (this.$refs.formDetails) {
        this.$refs.formDetails.resetValidation();
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 却下モーダルのリセット処理
     */
    close(item) {
      // メソッド名を定義
      const method_name="close"
      // オペレーションタイプを定義
      const operation_type="0004"
      // バリデーションエラーをリセット
      this.resetValidate();
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      item.dialog = false;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * バリデーションチェック(却下モーダル)
     */
    async validateCheckDetails() {
      // メソッド名を定義
      const method_name="validateCheckDetails"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 発注明細データのバリデーション
      let form = this.$refs.formDetails;
      if (!form.validate()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }// ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     *  MailFormを開く前に、Eメールが認証済か調べる
     */
    async checkAuthUser() {
      // メソッド名を定義
      const method_name="checkAuthUser"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // SESの認証情報を取得
      if (!(await this.getSesData())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      return true;
    },
    /**
     *  SESの認証情報を取得
     */
    async getSesData() {
      // メソッド名を定義
      const method_name="getSesData"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      const credentials = await Auth.currentCredentials();
      let ses = new AWS.SES({
        credentials: Amplify.Auth.essentialCredentials(credentials),
        region: AWS_DEFAULT_REGION,
        apiVersion: SES_API_VERSION
      });
      let params = {
        Identities: [
          this.loginUserAddress
        ]
      };
      let authData = "";
      try {
        const result = await ses.getIdentityVerificationAttributes(params).promise();
        authData = JSON.stringify(result.VerificationAttributes)
        // 認証済みかどうかの結果を変数に格納
        this.authEmail = authData.indexOf('Success') !== -1 ? true : false;
      } catch (error) {
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return authData.indexOf('Success') !== -1;
    },
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.scroll-lock-search table th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}
.scroll-lock-search table td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
.scroll-lock-search table td:nth-child(2) {
  white-space: nowrap;
}
</style>